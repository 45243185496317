<template>
  <div>
    <WeRowInput
      v-if="data.hasOwnProperty('is_active')"
      class="mb-2"
      form="vSelect"
      size="sm"
      name="is_active"
      label="Durum"
      placeholder="Durum"
      v-model="data.is_active"
      v-bind:option-prop="is_active_options"
      v-bind:clearable="false"
    />
    <WeRowInput
      class="mb-2"
      form="vSelect"
      size="sm"
      name="title"
      label="Meta Başlık"
      placeholder="Meta Başlık"
      v-model="data.seo_title"
      v-bind:option-prop="is_filled_options"
      v-bind:clearable="false"
    />
    <WeRowInput
      class="mb-2"
      form="vSelect"
      size="sm"
      name="title"
      label="Meta Anahtar Kelimeleri"
      placeholder="Meta Anahtar Kelimeleri"
      v-model="data.seo_keywords"
      v-bind:option-prop="is_filled_options"
      v-bind:clearable="false"
    />
    <WeRowInput
      class="mb-2"
      form="vSelect"
      size="sm"
      name="title"
      label="Meta Açıklama"
      placeholder="Meta Açıklama"
      v-model="data.seo_description"
      v-bind:option-prop="is_filled_options"
      v-bind:clearable="false"
    />
  </div>
</template>
<script>
export default {
  name: "WeSeoFilter",
  data() {
    return {
      is_filled_options: [
        {
          id: null,
          name: "Seçiniz",
        },
        {
          id: "empty",
          name: "Boş Olanlar",
        },
        {
          id: "filled",
          name: "Dolu Olanlar",
        },
      ],
      is_active_options: [
        {
          id: null,
          name: "Seçiniz",
        },
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
    };
  },
  props: {
    data: null,
  },
  model: {
    prop: "data",
  },
};
</script>
